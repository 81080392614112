/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  siteDomain: 'nosite6',
  // 标题，此项修改后需要重启项目
  title: 'poph5games',
  // 网站标题
  siteTitle: 'poph5games',
  // 网站加载时显示的内容
  siteLoading: 'Loading，wait......',
  // 网站描述
  siteDescription:
    'HTML5 GAME,Mircro Game,Web Game,Hot Game,Puzzle Game,Action Game,Kids Game,Strategy Game,Beauty Game,Unity3d Game,Sports Game,Home Game',
  // 网站关键字
  siteKeywords:
   'HTML5 GAME,Mircro Game,Web Game,Hot Game,Puzzle Game,Action Game,Kids Game,Strategy Game,Beauty Game,Unity3d Game,Sports Game,Home Game',
  // 白名单配置，不用令牌就能访问
  whileList: ['/', '/login', '/about'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // 备案号
  recordTitle: ''
}
